/* eslint-disable vue/no-side-effects-in-computed-properties */
<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";
import { Pie,Bar } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  LinearScale,
  CategoryScale
} from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, ArcElement, BarElement, LinearScale, CategoryScale)


/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout,Pie,Bar},
  data() {
    return {
      title: "",
      chartData: {
        labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
            data: [40, 20, 80, 10]
          }
        ]
      },
      chartDataB: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      chartOptionsB: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed : {
    loading(){
        return this.$store.getters['users/loading'];
    },
    counter(){
        return this.$store.getters['users/counter'];
    },
    piePlatformdata(){
        var data = this.$store.getters['users/counter']
        if(data != null){
            var label = data.grap.map((v)=>v._id == "" ? "Other" : v._id)
            var counter = data.grap.map((v)=>v.count)
            var color = data.grap.map((v)=>"#"+Math.floor(Math.random()*16777215).toString(16))
            var sheets = {
                labels : label,
                datasets : [{
                    backgroundColor : color,
                    data : counter
                }]
            } 
            return sheets
        }else{
            return {}
        }
    },
    usersstatisticCharts(){
            var data = this.$store.getters['users/counter']
            if(data != null){
                var label = data.country.map((v)=>v._id == "" || v._id == null ? "Other" : v._id+" | "+v.country)
                var counter = data.country.map((v)=>v.count)
                var sheets =  {
                    labels: label,
                    datasets: [
                        {
                        label: 'Jumlah User PerCity',
                        backgroundColor: "#"+Math.floor(Math.random()*16777215).toString(16),
                        data: [...counter]
                        }
                    ]
                }
                return sheets
            }else{
                return {}
            }
    },
  },
  created(){
  },
  mounted(){
    this.$store.dispatch("users/counter")
  },
  methods : {
  }

};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Dashboard </h4>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Jaybod Collaborations Storage.</li>
            </ol>
          </div>
        </div>
            <div class="row" v-if="counter != null">
              <div class="col-md-4">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                    <p class="text-muted font-weight-medium">Total User</p>
                                    <h4 class="mb-0">{{counter.total}}</h4>
                                </div>

                                <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                    <span class="avatar-title">
                                        <i :class="`bx bx-group font-size-24`"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
              </div>
              <div class="col-md-4">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                    <p class="text-muted font-weight-medium">Free User</p>
                                    <h4 class="mb-0">{{counter.freeUser}}</h4>
                                </div>

                                <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                    <span class="avatar-title">
                                        <i :class="`bx bx-group font-size-24`"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
              </div>
              <div class="col-md-4">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                    <p class="text-muted font-weight-medium">Paid User</p>
                                    <h4 class="mb-0">{{counter.paidUser}}</h4>
                                </div>

                                <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                    <span class="avatar-title success">
                                        <i :class="`bx bx-group font-size-24`"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
              </div>
              <div class="col-md-4">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                    <p class="text-muted font-weight-medium">Active User</p>
                                    <h4 class="mb-0">{{counter.activeUser}}</h4>
                                </div>

                                <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                    <span class="avatar-title success">
                                        <i :class="`bx bx-group font-size-24`"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
              </div>
              <div class="col-md-4">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                    <p class="text-muted font-weight-medium">InActive User</p>
                                    <h4 class="mb-0">{{counter.nonActiveUser}}</h4>
                                </div>

                                <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-danger">
                                    <span class="avatar-title danger">
                                        <i :class="`bx bx-group font-size-24`"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
              </div>
              <div class="col-md-4">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                    <p class="text-muted font-weight-medium">Payment Suspend</p>
                                    <h4 class="mb-0">{{counter.suspend}} user</h4>
                                </div>

                                <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-danger">
                                    <span class="avatar-title danger">
                                        <i :class="`bx bx-group font-size-24`"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
              </div>
              <div class="col-md-4">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                    <p class="text-muted font-weight-medium">Deleted Account</p>
                                    <h4 class="mb-0">{{counter.deleted}} user</h4>
                                </div>

                                <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-danger">
                                    <span class="avatar-title danger">
                                        <i :class="`bx bx-group font-size-24`"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
              </div>
              <div class="col-md-4">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-body">
                                    <p class="text-muted font-weight-medium">Wait Verify Account</p>
                                    <h4 class="mb-0">{{counter.unsuspend}} user</h4>
                                </div>

                                <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-danger">
                                    <span class="avatar-title danger">
                                        <i :class="`bx bx-group font-size-24`"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
              </div>
            </div>
        <div class="row" v-if="counter != null">
            <div class="col-md-4">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card mini-stats-wid"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                        <h2 class="card-title">User registered from platform</h2>
                                <Pie
                                    :chart-options="chartOptions"
                                    :chart-data="piePlatformdata"
                                    :chart-id="'doughnut-chart'"
                                    :dataset-id-key="'label'"
                                    :height="400" 
                                />
                        </div>
                    </div>
                </b-overlay>
            </div>
            <div class="col-md-8">
                <b-overlay :show="loading" rounded="sm">
                <div class="card" with overlay :aria-hidden="loading ? 'true' : null">
                    <div class="card-body">
                    <h2 class="card-title">User by City</h2>
                    <Bar :chart-id="'userCity'" :dataset-id-key="'label'" :chart-options="chartOptionsB" :chart-data="usersstatisticCharts"
                        :height="400" />
                    </div>
                </div>
                </b-overlay>
            </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
